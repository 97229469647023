import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { Link } from "gatsby"
import FooterMenu from "../footerMenu/footerMenu"
import Newsletter from "../newsletter/newsletter"

import Heading from "../../utils/heading"

const FooterWrapper = styled.footer.attrs({
  className: "dontPrint",
})`
  background-color: ${props => props.theme.colors.secondary};
  color:white;
  overflow:auto;

  .tagLine{
    ${props => props.theme.wrappersContainers.primaryWrapper};
    ${props => props.theme.wrappersContainers.primaryContainer};
    text-align:center;
  }

  .foot{
    ${props => props.theme.wrappersContainers.primaryWrapper};
    ${props => props.theme.wrappersContainers.primaryContainer};
    ${props => props.theme.fonts.smallerFontSize};
    a{
      color:white;
    }
  }

`
// const FooterFeefo = styled.div`
//   ${props => props.theme.containers.primary}
//   padding-top: 1rem;
//   padding-bottom: 1rem;
//   width: 100%;
//   background-color: white;

// `

const Footer = props => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      wp {
        companyDetails {
          companyDetails {
            companyname
            companyemail
            companyphonenumber
          }
        }
      }
      allWpMenu(filter: {name: {eq: "Footer Menu"}}) {
        edges {
          node {
            menuItems {
              nodes {
                label
                parentId
                menu_icon {
                  menuIcon {
                    selectMenuIcon
                    showMenuIcon
                    showMenuText
                  }
                }
                subMenu: childItems {
                  nodes {
                    menu_icon {
                      menuIcon {
                        selectMenuIcon
                        showMenuIcon
                        showMenuText
                      }
                    }
                    url
                    path
                    label
                  }
                }
              }
            }
          }
        }
      }
      file(name: { eq: "feefoAward19" }) {
        childImageSharp {
          fixed(width: 208, height: 40) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)

  const deets = data.wp.companyDetails.companyDetails
  const parentMenus = data.allWpMenu.edges[0].node.menuItems.nodes.filter(i => !i.parentId);

  return (

    <>

      <Newsletter />

      <FooterWrapper>

        <Heading type="tagLine" as="h3" color="white">
          Outstanding holiday cottages with a personal touch
        </Heading>

        <FooterMenu
          footerMenu={parentMenus}
        />
        {/* <FooterFeefo>
          <Img fixed={data.file.childImageSharp.fixed} />
        </FooterFeefo> */}

        <div className="foot">
          All rights reserved © {new Date().getFullYear()} {deets.companyname} - <Link to="/sitemap" title="Sitemap">Sitemap</Link>
        </div>

      </FooterWrapper>

    </>
  )
}

export default Footer
