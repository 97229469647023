import React from "react"
import addToMailchimp from "gatsby-plugin-mailchimp"
import TextField from "@material-ui/core/TextField"
import Heading from "../../utils/heading"

export default class MailChimpForm extends React.Component {
  constructor() {
    super()
    this.state = {
      FNAME: null,
      EMAIL: null,
      result: null,
      send: null,
      message: null,
    }
  }
  _handleSubmit = async (event) => {
    event.preventDefault()
    const result = await addToMailchimp(this.state.EMAIL, {
      ...this.state,
    })
    this.setState({ result: result, send: result.result, message: result.msg })
  }
  handleChange = (event) => {
    this.setState({ [`${event.target.name}`]: event.target.value })
  }

  render() {
    const statee = this.state

    function validation() {
      if (statee.send === "success") {
        return (
          <div
            className="vali"
            dangerouslySetInnerHTML={{ __html: statee.message }}
          />
        )
      } else if (statee.send === "error") {
        return (
          <div
            className="vali"
            dangerouslySetInnerHTML={{ __html: statee.message }}
          />
        )
      } else {
        return (
          <div className="vali">
            You are signing up to receive marketing updates from us and can opt
            out at any time. Visit our{" "}
            <a title="Privacy Policy" target="_blank" href="/privacy-policy/">
              Privacy Policy
            </a>{" "}
            for more information.
          </div>
        )
      }
    }

    return (
      <form onSubmit={this._handleSubmit} className="newsForm">
        <div className="text">
          <Heading type="title" as="h3" color="white">
            Subscribe to our newsletter
          </Heading>

          <p>to keep up to date with news and offers!</p>
        </div>
        <div className="name">
          <TextField
            id="outlined-name-input"
            label="Name"
            type="text"
            name="FNAME"
            variant="outlined"
            fullWidth
            onChange={this.handleChange}
            required
          />
        </div>
        <div className="email">
          <TextField
            id="outlined-email-input"
            label="Email"
            type="email"
            name="EMAIL"
            variant="outlined"
            fullWidth
            onChange={this.handleChange}
            required
          />
        </div>
        <div className="subbers">
          <button label="Submit" type="submit">
            Submit
          </button>
          {validation()}
        </div>
      </form>
    )
  }
}
