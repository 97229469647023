import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import Locations from "./filters/locations"

const Wrapper = styled.div`

    flex-grow: 1;
    margin: 1rem 0 0 0;
    @media ${(props) => props.theme.mq.lg} {
      margin: 0 1rem 0 0;
    }

`
const PropertySearch = ({ concise, onchange }) => {

  //static query
  const filterData = useStaticQuery(
    graphql`
      query {
        bookingGraph {
          getProperties {
            properties {
              name
              wpurl
              propRef
            }
          }
        }
      }
    `
  )

  function propSelect(value) {
    console.log("property selected: ", value.title);
    onchange && onchange(value);
  }

  //Create a composite list of locations (and properties if concise)
  function locationList() {
    //var propertyList = filterData.allWpProperty.edges
    var propertyList = filterData.bookingGraph.getProperties.properties

    const properties = propertyList
      .map((property, ndx) => ({
        title: property.name + " / " + property.propRef,
        id: ndx,
        url: new URL(process.env.GATSBY_CMS_ADDR + property.wpurl).pathname
      }))
      .sort((a, b) => -b.title.localeCompare(a.title));

    return properties;
  }

  return (
    <Wrapper>
      <Locations
        onSelect={propSelect}
        value={null}
        locations={locationList()}
        placeholder={"Find property by name/ref"}
        variant={"standard"}
      />
    </Wrapper>
  )
}

export default PropertySearch
