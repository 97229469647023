import React, { useContext } from "react"
import { Link } from "gatsby"
import styled, { keyframes } from "styled-components"
import ButtonLink from "../button/buttonLink"
import Gallery from "../../utils/gallery"
import Icon from "../../utils/icon"

// Vars
import { slideInLeft, fadeIn } from "react-animations"
import { WindowDimensionsCtx } from "../../utils/windowDimensionsProvider"

const slideInLeftAnimation = keyframes`${slideInLeft}`

const PanelContent = styled.div.attrs({
  className: "panelContent",
  })`
     ${(props) => props.theme.wrappersContainers.primaryWrapper};
`

const PanelContainer = styled.div.attrs({
  className: "panelContainer",
  })`
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  order: 1;
  width: 100%;
  height: 100%;
  z-index: 900;
  background-color: white;

  @media ${props => props.theme.mq.lg} {
    height: auto;
    min-height: 90px;
    transition: all 0.2s linear 0s;
    border-top: 2px solid ${props => props.theme.colors.lightGrey};
    margin: 0 auto;

    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
  }
`
const PanelNav = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 3rem;
  padding: 1rem 1rem;

  @media ${props => props.theme.mq.lg} {
    display: none;
  }

  button{
    position: relative;
    top:-4px;
  }
  svg{
    fill:${props => props.theme.colors.secondary}
  }
`

const fadeInAnimation = keyframes`${fadeIn}`

const MenuContainer = styled.div.attrs({
  className: "panelMenuContainer",
})`
  position: relative;
  display: flex;
  width: 100%;
  animation: 0.2s ${slideInLeftAnimation};

  @media ${props => props.theme.mq.lg} {
    padding: 2rem 0rem;
    animation: 0.2s ${fadeInAnimation} linear 0s;
  }
`
const PanelImage = styled.div.attrs({
  className: "panelImage",
})`
  overflow:hidden;
  width: 384px;
  div{
    max-height:100%;
  }

  .galleryWrapper{

  display: grid;
  grid-template-rows: repeat(12, 1fr);
  grid-template-columns: repeat(12, 1fr);
  align-items: start;

    &.gallery-1 {
      .item-0 {
        grid-area: 1/1/13/13;
      }
    }
  }

`

const MenuItemList = styled.ul.attrs({
  className: "panelMenuList",
})`
  list-style-type: none;
  display: grid;
  padding: 1rem 1rem;
  margin:0;
  width: 100%;

  @media ${props => props.theme.mq.lg} {
    grid-auto-flow: column;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
    padding: 0 2rem;
  }

`

const MenuItem =styled.li.attrs({
  className: "panelMenuItem"
})`
  display: block;
  text-align: left;
  padding: 1rem 0;
  text-transform: uppercase;

  width: 100%;
  border-top: 1px solid transparent;
  border-bottom: 1px solid ${props => props.theme.colors.lightGrey};
  @media ${props => props.theme.mq.lg} {
    padding: 0.5rem 0;
    border-bottom: 0;
  }
  a {
    display:block;
    color: inherit;
    text-decoration: none;
    transition: all 0.3s ease;
    &:hover{
      color: ${props => props.theme.colors.secondary};
      //   border-bottom: 1px solid ${(props) => props.theme.colors.secondary};
    }
  }
`

const MenuPanel = props => {

  const mediaContext = useContext(WindowDimensionsCtx)
  const menu = props?.panelData;
  const menuItems = menu?.childItems?.subMenu;
  const onClose = props.onClose;
  //const open = props.open;
  const pClass = props.className;

  // const subMenus = () => {
  //   return menuItems?.map((item, key) => {
  //     return <li key={key}>
  //       {/* {itemFeature(item)} */}
  //       {checkLink(item)}
  //     </li>
  //   });
  // }

  const checkLink = item => {
    let link = ""

    const onlyPath = item.path;

    if (item !== null) {
      link =
        onlyPath.indexOf("http://") === 0 ||
          onlyPath.indexOf("https://") === 0 ||
          onlyPath.indexOf("mailto:") === 0 ? (
            <a href={onlyPath}><span dangerouslySetInnerHTML={{ __html: item.label }} /></a>
          ) : (
            <Link to={onlyPath}
              onClick={e => optionClick(e, -1)}
            >
              <span dangerouslySetInnerHTML={{ __html: item.label }} />
            </Link>
          )
      return link
    }

    return link
  }


  // const itemFeature = (item) => {

  //   const menuFeature = item.menu_feature?.menuFeature;
  //   return menuFeature?.showMenuFeature &&
  //     [mediaContext.width] > 1279 && (
  //       <PanelFeature
  //         //   key={item}
  //         hasPanelFeature={menuFeature.showMenuFeature}
  //         excerpt={menuFeature.excerpt}
  //         title={menuFeature.title}
  //         images={menuFeature}
  //         linkText={item.title}
  //         linkUrl={item.url}
  //       />
  //     )
  // }

  const showMainPanelFeature = () => {
    const menuFeature = menu.menu_feature?.menuFeature;
    return menuFeature?.showMenuFeature &&
      [mediaContext.width] > 1279 && (
        // <PanelFeature
        //   //key={i}
        //   hasPanelFeature={menuFeature.showMenuFeature}
        //   excerpt={menuFeature.excerpt}
        //   title={menuFeature.title}
        //   images={menuFeature}
        //   linkText={menu.title}
        //   linkUrl={menu.url}
        // />
        <PanelImage>
          <Gallery content={menuFeature} />
        </PanelImage>
      );
  }

  const optionClick = (e, i) => {
    if (onClose) {
      onClose(true);
    }
  }

  const leave = (e, i) => {
    // if (e) {
    //   e.preventDefault()
    // }
    if (onClose) {
      onClose(false);
    }
  }

  if (!menu)
    return null;

  return (
    <PanelContainer className={pClass +" active"} onMouseLeave={e => leave(e, -1)}>
      <PanelContent>
        <PanelNav>
          <ButtonLink onClick={e => leave(e, -1)} >
            <Icon name="cheveron-left" height="1.5rem" />
          </ButtonLink>
          <ButtonLink onClick={e => leave(e, -1)} >
            <Icon name="close" height="1rem" />
          </ButtonLink>

        </PanelNav>

        <MenuContainer>
          {showMainPanelFeature()}
          <MenuItemList>
            {
              menuItems?.map((item, key) => {
                return <MenuItem key={key}>
                  {/* {itemFeature(item)} */}
                  {checkLink(item)}
                </MenuItem>
              })
            }
            {/* {subMenus()} */}
          </MenuItemList>
        </MenuContainer>
      </PanelContent>
    </PanelContainer>
  )
}

export default MenuPanel
