import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Icon from "../../../utils/icon"
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from "@material-ui/core/styles";
import colors from "../../../theme/colors"

const useStyles = makeStyles({

    root: {
        backgroundColor: 'white',
        borderRadius: '4px',
    },
    option: {
        padding: "0.75rem",
        '& .innerLi': {
            display: 'flex',
            color: colors.primaryFontColor,
            textDecoration: "none",
            '& .svgWrapper': {
                marginRight: '1rem',
                position: "relative",
                fill: colors.primary,
                // '& svg': {
                //     position: "absolute",
                //     top: "3px",
                // }
            }
        }
    },

});


const Wrapper = styled.div.attrs({
    className: "singleFilter"
})`
    position: relative;

`

const Locations = ({ value, onSelect, locations, placeholder, variant }) => {

    // i believe if you pass in an object that looks like an item in the list but isnt the actual it would fail without this.
    const validatedValue = value ? (locations?.filter(el => (el.id === value?.id || el.title === value?.title))[0]) : null;

    const optionComparer = (option, value) => {
        return option.id === value?.id
    };

    //on change function
    const onChange = (event, newValue) => {

        onSelect(newValue);

        // console.log('Location change ', newValue);

    };

    const locType = (option) => {
        if (option.url) {
            return (
                <>
                    <Link to={option.url} className="innerLi">
                        <Icon name="property" height="22px" />
                        {option.title}
                    </Link>
                </>
            )
        } else {
            return (
                <div className="innerLi">
                    <Icon name="location" height="22px" />
                    {option.title}
                </div>
            )
        }

    }

    const classes = useStyles();

    return (

        <Wrapper>
            <Autocomplete
                id="location-selector"

                blurOnSelect={true}

                options={locations}

                getOptionLabel={(option) => (option?.title ?? "Unknown")}

                // defaultValue={defaultState.value}

                value={validatedValue}

                onChange={onChange}

                getOptionSelected={optionComparer}

                classes={classes}

                renderInput={(params) => (<TextField
                    {...params}
                    placeholder={placeholder ? placeholder : "Where would you like to go?"}
                    variant={variant}
                />)
                }
                renderOption={locType}
            />
        </Wrapper>

    );
}

export default Locations