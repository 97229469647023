import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Icon from "../../utils/icon"

const ListMenuWrapper = styled.div.attrs(props => ({

}))`

  visibility: hidden;
  opacity: 0;
  height:0;

  @media ${props => props.theme.mq.md} {
    visibility: visible;
    opacity: 1;
    height:100%;
    margin-bottom:1rem;
  } 

  &.active{
    visibility: visible;
    opacity: 1;
    height:100%;
    margin:1rem 0 0 0;
  }

  ul {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: ${props => props.alignment === true ? "row" : "column"};
    a {
      color: inherit;
      text-decoration: none;
      border-bottom: 1px solid transparent;
      margin-bottom: 0.5rem;
      padding-bottom: 0.5rem;
      display: inline-block;
      &:hover {
        cursor: pointer;
        border-bottom: 1px solid white;
      }
    }
    li {
      margin-right: ${props => props.alignment === true ? "1rem" : "0rem"};
      display: ${props => props.alignment === true ? "flex" : "block"};
      justify-content: ${props => props.alignment === true ? "center" : "unset"};
      align-items: ${props => props.alignment === true ? "center" : "unset"};
      &:last-child {
        margin-right: ${props => props.alignment === true ? "0rem" : "unset"};
      }
    }
  }
`



const ListMenu = props => {

  const checkLink = item => {

    const onlyPath = item.path;

    const menuIcon =
      "menu_icon" in item && item.menu_icon.menuIcon.showMenuIcon === true ? (
        <Icon
          name={item.menu_icon.menuIcon.selectMenuIcon}
          height="15px"
          fill="white"
        />
      ) : (
          ""
        )
    const link =
      onlyPath.indexOf("http://") === 0 ||
        onlyPath.indexOf("https://") === 0 ||
        onlyPath.indexOf("mailto:") === 0 ||
        onlyPath.indexOf("#") === 0 ||
        onlyPath.indexOf("tel:") === 0 ? (
          <li>
            <a href={onlyPath}>
              {menuIcon}
              <span dangerouslySetInnerHTML={{ __html: item.menu_icon && item.menu_icon.menuIcon.showMenuText !== null ? item.label : "" }} />
            </a>
          </li>
        ) : (
          <li>
            <Link to={onlyPath}>
              {menuIcon}
              <span dangerouslySetInnerHTML={{ __html: item.menu_icon && item.menu_icon.menuIcon.showMenuText !== null ? item.label : "" }} />
            </Link>
          </li>
        )

    return <>{link}</>
  }

  const getSubMenu = () => {
    return props.menuItem.nodes !== null
      ? props.menuItem.nodes.map((menuItem, i) => {
        return menuItem !== null && menuItem !== "undefined" ? (
          <span key={i}>{checkLink(menuItem)}</span>
        ) : (
            ""
          )
      })
      : ""
  }

  return (
    <ListMenuWrapper className={"listMenuWrapper " + props.class}>
      <ul className={props.templateName}>{getSubMenu()}</ul>
    </ListMenuWrapper>
  )
}

export default ListMenu
