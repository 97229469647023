import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client';
import { onError } from "@apollo/client/link/error";
// accounts required for customer login
// import { AccountsClient } from '@accounts/client'
// import { AccountsGraphQLClient } from '@accounts/graphql-client'
// import { AccountsClientPassword } from '@accounts/client-password'
// import { accountsLink } from '@accounts/apollo-link'
// required for ssr
import fetch from "cross-fetch"

const httpLink = new HttpLink({
  fetch,
  uri: process.env.GATSBY_GRAPH_ADDR + "/graphql",
  credentials: 'same-origin',
})

const cache = new InMemoryCache({
  typePolicies: {
    PropertyAttributeFacet: {
      // diable normalisation  for facets.
      keyFields: false,
    },
  },
});

const accountsApolloClient = new ApolloClient({
  fetchOptions: {
    mode: 'no-cors',
  },
  link: ApolloLink.from([httpLink]),
  cache,
})

// const accountsTransport = new AccountsGraphQLClient({
//   graphQLClient: accountsApolloClient,
// })

//export const client = new AccountsClient({}, accountsTransport)

//export const accounts = new AccountsClientPassword(client)

//const authLink = accountsLink(() => client)

const errorHandling = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      ),
    );
  if (networkError) console.log(`[Network error]: ${networkError}`);
})

export const apollo = new ApolloClient({
  cache: cache,
  link: ApolloLink.from([
    errorHandling,
  //  authLink,
    httpLink
  ]),
})
