import { createTheme } from "@material-ui/core"
import colors from "./../theme/colors"

const theme = createTheme({
  typography: {
    fontFamily: [
      'OpenSans',
      'sans-serif',
    ].join(','),
    color: colors.primaryFontColor,
    backgroundColor: "white",
  },
  palette: {
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
    },
    tertiary: {
      main: colors.tertiary,
    },
  },
});

export default theme
