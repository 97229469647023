import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

const Wrapper = styled.div`
height: 100%;


/* display: grid;
grid-template-rows: repeat(12, 1fr);
grid-template-columns: repeat(12, 1fr);

  &.gallery-1 {
    .item-0 {
      grid-area: 1/1/13/13;
    }
  }

  &.gallery-2 {
    .item-0 {
      grid-area: 1/1/9/9;
    }

    .item-1 {
      grid-area: 5/5/13/13;
    }
  }

  &.gallery-3 {
    .item-0 {
      grid-area: 1/1/6/6;
    }

    .item-1 {
      grid-area: 4/4/10/10;
    }

    .item-2 {
      grid-area: 8/8/13/13;
    }
  }

  &.gallery-4 {
    .item-0 {
      grid-area: 1/1/7/7;
    }

    .item-1 {
      grid-area: 1/7/7/13;
    }

    .item-2 {
      grid-area: 7/1/13/7;
    }

    .item-3 {
      grid-area: 7/7/13/13;
    }
  } */

  .galleryItem{
    position: relative;
      span{
        display:none;
    }
  }


`

const Gallery = React.memo(({ content, icon }) => {

  const numRows = content.images.length

  return (
    <Wrapper className={`galleryWrapper gallery-` + numRows}>
      {content.images.map((image, i) => {
        return (
          <div className={`galleryItem item-` + i} key={i}>
            <Img
              fluid={image.localFile.childImageSharp.fluid}
              key={i}
              alt={image.altText}
            />
            <span>{image.altText}</span>
          </div>
        )
      })}
    </Wrapper>
  )

})

export default Gallery
