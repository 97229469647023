import React, {  useState } from "react"
import { Link } from "gatsby"
import styled, { keyframes } from "styled-components"
import MenuPanel from "./menu-panel"
import Icon from "../../utils/icon"
import ButtonLink from "../button/buttonLink"
import { slideInLeft } from "react-animations"
//import SVG from "react-inlinesvg"

const slideInLeftAnimation = keyframes`${slideInLeft}`

const PanelNav = styled.div`
  height: 3rem;
  padding: 1rem 2rem;
  text-align: right;

  @media ${props => props.theme.mq.lg} {
    display: none;
  }
  svg{
    fill:${props => props.theme.colors.secondary}
  }
`
const MenuItem =styled.li.attrs({
  className: "menuItem"
})`
  display: block;
  text-align: left;
  margin: 0 1rem;
  padding: 1rem 0;

  border-top: 1px solid transparent;
  text-transform: uppercase;
  text-decoration: none;
  width: 100%;
  border-bottom: 1px solid ${props => props.theme.colors.lightGrey};

  @media ${props => props.theme.mq.lg} {
    width: auto;
    padding: 1rem 0rem;
    border-bottom: revert;
  }

  /* &:last-of-type{
    margin-right:0;
  } */
  
  a {
        color: inherit;
        text-decoration: none;
        border-bottom: 1px solid transparent;
  }
  button {
    text-transform: inherit;
  }
  button:hover,
  a:hover {
    border-bottom: 1px solid ${(props) => props.theme.colors.secondary};
  }
  .svgWrapper{
    float: right;
    @media ${props => props.theme.mq.lg} {
      display: none;
    }
  }

`

const MenuItemList = styled.ul`
  list-style-type: none;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1rem;
  margin: 0;
 // height: 100%;

  @media ${props => props.theme.mq.lg} {
    background-color: inherit;
    width: auto;
    padding: 0rem 1rem;
    order: 2;
  }    
  `

const MenuContainer = styled.nav`
  background-color: white;
  color: ${(props) => props.theme.colors.primaryFontColor};
  ${(props) => props.theme.fonts.smallerFontSize};
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  z-index: 100;
  display: none;

  .divider{
    @media ${props => props.theme.mq.lg} {
      width: 100%;
      position: absolute;
      left: 0;
    }
  }

  @media ${props => props.theme.mq.lg} {
    background-color: revert;
    width: revert;  
    height: revert;
    position: revert;
    top: revert;
    z-index: revert;

  }
  &.active {
    display:revert;
    animation: 0.2s ${slideInLeftAnimation};
    @media ${props => props.theme.mq.lg} {
      animation: unset;
    }
  }

`

//=================================
//
// const Menu = React.memo(props => {
const Menu = props =>{
  
  const onClose = props.onClose;
  const open = props.open;
  const children  = props.children;
  const menu = props.menu;
  //const name = props.name;

  // parent menu items
  const menuItems = () => {
    return (menu.menuItems.items.filter(i => !i.parentId));
  }

  //remember any open submenus
  const defaultState = {
      panelID: -1,
  }

  const [ menuState, updateMenuStatus ] = useState(defaultState)
  const thisMenu = menuState;
  //
  const renderLink = (item,i) => {
    const itemPath = item.path;

    const menuIcon =
      "menu_icon" in item && item.menu_icon.menuIcon.showMenuIcon === true ? (
        <Icon
          name={item.menu_icon.menuIcon.selectMenuIcon}
          height="15px"
          fill="white"
        />
      ) : (
          ""
        )

    function iconz() {

      if (item.menu_icon) {
        return item.menu_icon.menuIcon.showMenuText !== null ? item.label : ""
      } else {
        return item.label
      }

    }

    const link =
      itemPath.indexOf("http://") === 0 ||
        itemPath.indexOf("https://") === 0 ||
        itemPath.indexOf("mailto:") === 0 ||
        itemPath.indexOf("#") === 0 ||
        itemPath.indexOf("tel:") === 0 ? (
          <a href={itemPath}
            // onMouseEnter={e => optionSelect(e, i)}
          >
            {menuIcon}
            {iconz()}
          </a>
        ) : (
          <Link 
            to={itemPath}
            //onMouseEnter={e => optionSelect(e, i)}
            onClick={e => optionSelect(e, i)}
          >
            {menuIcon}
            {iconz()}
          </Link>
        )

    return <>{link}</>
  }

  //
  const renderItem = (item, key, props, i) => {
    if (
      item?.childItems?.subMenu &&
      item.childItems.subMenu.length > 0
    ) {
      return (
        <>
          <ButtonLink
            onClick={e => subMenuSelect(e, i)}
            onMouseEnter={e => subMenuSelect(e, i)}
            onKeyDown={e => subMenuSelect(e, i)}
          >
            {item.label}
          </ButtonLink>
          <Icon name="cheveron-right" height="1rem" />
        </>
      )
    } else {
      return renderLink(item, i)
    }
  }

  //
  const subMenu = pId => {
    if(pId>=0){
      const menu = menuItems()[pId];
      return( menu?.childItems?.subMenu?.length ? menu : null);
    }
    return null;
  }

  // A submenu has closed
  const subMenuClose = (select) =>{
  
    updateMenuStatus({
      ...menuState,
      panelID: -1 ,
    })
    document.body.classList.remove("noScroll")

    // submenu an item was selected so make sure any subordinates close
    if(select && onClose){
        onClose(select);
    }
  }

  
  //A menu option was selected
  const subMenuSelect = (e, i) => {
    const isMenu = subMenu(i)!=null;
   // console.log(`Menu sekect ${i} is menu ${isMenu}`)
    updateMenuStatus({
      ...menuState,
      panelID: isMenu?i:-1 ,
    })
    if(!isMenu && onClose){
      // a submenu was selected so tell the parent
      onClose(true);
    }
  }

  //A Link option was selected
  const optionSelect = (e, i) => {
   // console.log(`Option select ${i} is option ${!isMenu}`)
    updateMenuStatus({
      ...menuState,
      panelID: -1 ,
    })
    closeMenu();
  }

  const closeMenu =() => {
    document.body.classList.remove("noScroll")

    if(onClose)
      onClose(true);
  }
  //**** renderer ******** */  
  return (

    <MenuContainer className={ open ?  "active" :"inactive" }>

      <PanelNav>
        <ButtonLink onClick={(e) => closeMenu(e)} >
            <Icon name="close" height="1rem" />
        </ButtonLink>
      </PanelNav>
          
      <MenuItemList>
        {menuItems().map((menuItem, i) => {
            return (
              <MenuItem key={i}>
                {renderItem(menuItem, i, props, i)}
              </MenuItem>
            )
          }
        )}
      </MenuItemList>

      {children}

      <div className= "divider">
        {/* submenu */}
        <MenuPanel      
          panelData={subMenu(thisMenu.panelID)}
          onClose ={subMenuClose}
          open={thisMenu.panelID>=0}
        />
      </div>
    </MenuContainer>
  )
}

export default Menu
