const mq = {

  xl: "screen and (min-width:1460px)",
  lg: "screen and (min-width:1280px)",
  md: "screen and (min-width:1024px)",
  sm: "screen and (min-width:768px)",
  xs: "screen and (min-width:576px)",

  breakPoints: {
    xl: 1460,
    lg: 1280,
    md: 1024,
    sm: 768,
    xs: 576,
  },


}

export default mq
