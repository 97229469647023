import React, { useContext, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import styled from "styled-components"
//import Icon from "../../utils/icon"
import Menu from "../menu/menu"
import LogoIcon from '../../../static/svgs/logos/logo-icon.svg'
import LogoText from '../../../static/svgs/logos/logo-text.svg'
import IconCall from '../../../static/svgs/inline/call.svg'
import IconMenu from '../../../static/svgs/inline/menu.svg'
import IconPadlock from '../../../static/svgs/inline/padlock.svg'
import IconHeart from '../../../static/svgs/inline/heart.svg'
import PropertySearch from "../search/propertySearch"
import PrivacyMessage from "../privacyMessage/privacyMessage"

import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";


import { WishListContext } from "../../utils/wishListProvider"

const HeaderContainer = styled.div.attrs({
  className: "dontPrint",
})`
  z-index: 2;
`

const TopMenuWrapper = styled.div.attrs({
  className: "topMenuWrapper",
})`
  order: 2;
  background-color: transparent;

  width: 100%;
  background-color: white;

  @media ${(props) => props.theme.mq.sm} {
    padding: 1rem 0;
  }

  nav {
    display: none;
    @media ${(props) => props.theme.mq.sm} {
      display: block;
    }
  }
`
const TopMenuContent = styled.div.attrs({
  className: "topMenuContent",
})`
  ${(props) => props.theme.wrappersContainers.primaryWrapper};
`

const Logo = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  min-height: 5.625rem;
  width: 200px;

  @media ${(props) => props.theme.mq.lg} {
    width: 320px;
  }

  .svgWrapper:first-of-type {
    width: 30%;
  }

  .svgWrapper:last-of-type {
    width: 65%;
    margin-left: 1rem;
  }
`



const TopMenu = styled.div.attrs({
  className: "topMenu",
})`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;

  .logoWrapper {
    width: 100%;
  }

  .mobileButtons {
    display: flex;
    justify-content: flex-end;
    width: 100%;

    @media ${(props) => props.theme.mq.lg} {
      display: none;
    }

    .tagLine {
      display: none;
      @media ${(props) => props.theme.mq.md} {
        display: block;
        ${(props) => props.theme.fonts.h6};
        color: ${(props) => props.theme.colors.secondary};
        ${(props) => props.theme.fonts.secondaryFont};
        margin: 0.3rem 2rem 0 0;
      }
    }

    .call {
      margin-right: 1rem;
      svg {
        fill: ${(props) => props.theme.colors.primary};
      }
    }

    svg {
      fill: ${(props) => props.theme.colors.secondary};
    }
  }

  ul {
    padding: 0;
    margin: 0;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
    @media ${(props) => props.theme.mq.lg} {
      width: 100%;
      flex-direction: row;
      justify-content: flex-end;
      margin-top: 0.5rem;
    }
    li {
      list-style: none;
      padding: 1rem 0;
      margin-bottom: 0px;
      border-bottom: 1px solid transparent;
      border-bottom-color: white;
      margin-bottom: 1rem;
      @media ${(props) => props.theme.mq.lg} {
        border-bottom-color: transparent;
        margin-bottom: 0;
        margin-left: 1rem;
        padding: 0;
      }
      a {
        text-decoration: none;
        color: inherit;
        display: flex;
        align-items: center;
        color: ${(props) => props.theme.colors.primaryFontColor};
        .svg {
          display: flex;
          align-items: center;
          max-height: 1rem;
          fill: ${(props) => props.theme.colors.primaryFontColor};
        }
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
`
const BottomMenuWrapper = styled.div.attrs({
  className: "bottomMenuWrapper",
})`
  margin: 0 auto;
  background: linear-gradient(
    180deg,
    #dbd8df,
  //  #dbd8df 10%,
  //  #f9f8fb 50%,
    white 15%
  );
  // &:before {
  //   background: #fff;
  //   background: linear-gradient(
  //     180deg,
  //     #dbd8df 0,
  //     #dbd8df 10%,
  //     #f9f8fb 50%,
  //     #fff
  //   );
  //   filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#dbd8df",endColorstr="#ffffff",GradientType=0);
  //   content: " ";
  //   position: absolute;
  //   height: 25%;
  //   width: 100%;
  // }
`

const BottomMenuContent = styled.div.attrs({
  className: "bottomMenuContent",
})`
  ${(props) => props.theme.fonts.smallerFontSize};
  ${(props) => props.theme.wrappersContainers.primaryWrapper};

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  overflow: hidden;
  padding: 0;
  //background-color: transparent;

  @media ${(props) => props.theme.mq.lg} {
    width: 100%;
    height: auto;
    position: relative;
    overflow: visible;
    padding: 0 3rem;
  }
`

const RightSide = styled.div.attrs({
  className: "rightSide",
})`
  display: none;

  @media ${(props) => props.theme.mq.lg} {
    color: ${(props) => props.theme.colors.secondary};
    font-size: 1rem;
    display: block;
    width: 100%;
    ${(props) => props.theme.fonts.secondaryFont};
    text-align: right;
  }

  a {
    text-decoration: none;
    color: ${(props) => props.theme.colors.secondary};
    ${(props) => props.theme.fonts.primaryFont};
    font-weight:normal;
  }

  .tagLine {
    ${(props) => props.theme.fonts.h6};
  }

  span {
    ${(props) => props.theme.fonts.primaryFont};
    margin-left: 2rem;
  }
`

const MenuButton = styled.button`
  outline: none;
  border: none;
 // z-index: 8;
  background-color: transparent;
  padding: 0;
  margin: 0;
`
const MobileExtras =styled.div`
  padding: 0rem 2rem;
  border-top: 1px solid transparent;
  text-transform: uppercase;
  text-decoration: none;
  width: 100%;
  
  // span {
  //   ${(props) => props.theme.fonts.smallerFontSize};
  //   position: relative;
  //   top: -7px;
  // }

  span {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 1rem 0rem;
    border-bottom: 1px solid ${props => props.theme.colors.lightGrey};

      &:last-of-type{
        padding: 1rem 0rem;
      }

    a {
      color: ${(props) => props.theme.colors.primaryFontColor};
      text-decoration: none;
    }
    .svgWrapper{
    //  height:20px;
      fill: ${(props) => props.theme.colors.primary};
      display:inline;
    }
}
`
const WishListWrapper = styled.div.attrs({
  className: "wishlist",
})`
  display: flex;

  svg {
    fill: ${(props) => props.theme.colors.primary};
    @media ${(props) => props.theme.mq.lg} {
      margin: 0.5rem 0.75rem 0.25rem 0.75rem;
    }  
  }
  a {
    text-decoration: none;
    color: ${(props) => props.theme.colors.primaryFontColor};
    ${(props) => props.theme.fonts.smallerFontSize};
    margin-right:1rem;
    @media ${(props) => props.theme.mq.lg} {
      margin-right:0;
    }
  }
  @media ${(props) => props.theme.mq.lg} {
    flex-direction: column;
    background-color: #dbd8df;
    align-items: center;
    border-radius: 0 0 0.25rem 0.25rem;
    ${(props) => props.theme.fonts.smallerFontSize};
  }
`

const Header = (props) => {
  return (
    <HeaderContainer>
        <HeaderInner themeClass="headerWrapper" {...props} />
    </HeaderContainer>
  )
}

const HeaderInner = (props) => 
{

  const data = useStaticQuery(graphql`
  query HeaderQuery {

    wp {
      companyDetails {
        companyDetails {
          companyname
          companyemail
          companyphonenumber
          companydescription
        }
      }
    }

      topMenu: wpMenu(name: {eq: "Top Nav"}) {
            menuItems {
              items: nodes {
                url
                path
                title
                childItems {
                  subMenu: nodes {
                    title
                    url
                    path
                    target
                    label
                    parentId
                    menu_feature {
                      menuFeature {
                        title
                        showMenuFeature
                        excerpt
                        fieldGroupName
                        images {
                          localFile {
                            childImageSharp {
                              fluid(quality: 100, maxWidth: 1920) {
                                ...GatsbyImageSharpFluid
                              }
                            }
                          }
                        }
                      }
                    }
                    menu_icon {
                      menuIcon {
                        showMenuText
                        showMenuIcon
                        selectMenuIcon
                        fieldGroupName
                      }
                    }
                  }
                }
                label
                menu_icon {
                  menuIcon {
                    selectMenuIcon
                    showMenuIcon
                    showMenuText
                  }
                }
              }
            }
            slug
            name
      }

      bottomMenu: wpMenu(name: {eq: "Main"}) {
            menuItems{
              items: nodes {
                url
                path
                title
                parentId
                menu_feature {
                  menuFeature {
                    showMenuFeature
                    images {
                      altText
                      localFile {
                        childImageSharp {
                          fluid(quality: 90, maxWidth: 768) {
                            ...GatsbyImageSharpFluid
                          }
                        }
                      }                    }
                  }
                }
                childItems {
                  subMenu: nodes {
                    
                      menu_feature {
                        menuFeature {
                          title
                          showMenuFeature
                          excerpt
                          fieldGroupName
                          images {
                            altText
                            localFile {
                              childImageSharp {
                                fluid(quality: 90, maxWidth: 768) {
                                  ...GatsbyImageSharpFluid
                                }
                              }
                            }
                          }
                        }
                      }
                      menu_icon {
                        menuIcon {
                          showMenuText
                          showMenuIcon
                          selectMenuIcon
                          fieldGroupName
                        }
                      }
                      title
                      url
                      target
                      label
                      path
                  }
                }
                label
              }
            }
            slug
            name
      }
    
  }
`)

  const deets = data.wp.companyDetails.companyDetails
  const topMenu = data.topMenu;

  const theme = useTheme();
  const bottomMenu = data.bottomMenu;

  const desktop = useMediaQuery(theme.breakpoints.up("lg"));

  const defaultState = {
      burger: false,
  }
  const [ menuState, updateMenuStatus ] = useState(defaultState);

  //wishlist count
  const { wishList } = useContext(WishListContext)

  const wishlistItemCount = () => { 
    const wlc = { wishList };
    return(wlc?.wishList.length ?? 0 );
  }

  function showPropertySearch(display) {

     if ( display ) {
       return (
         <PropertySearch concise={true} onchange={e => subMenuClose(true)} />
       )
     } else {
       return
     }
   }


  function wishlistCount(display) {
   // var wishlistCount = { wishList }

    if (wishlistItemCount() > 0 && display ) {
      return (
        <WishListWrapper>
          <Link to="/wishlist"
                onClick={e => subMenuClose(true)}                          >
            <IconHeart height="20px"/>
          </Link>
          {wishlistItemCount()}
        </WishListWrapper>
      )
    } else {
      return
    }
  }

  const subMenuClose = (select) =>{  
      if(select){
      updateMenuStatus({
        burger: false,
      })
    }
  }

  const burgerClick = () =>{  
    updateMenuStatus({
      burger: !menuState.burger,
    })
}

  return (
    <>
      <PrivacyMessage/>
      <TopMenuWrapper themeClass={props.themeClass}>
        <TopMenuContent>
          <TopMenu>
            <Link to={"/"} title="Home" className="logoWrapper">
              <Logo themeClass={props.themeClass}>
                <LogoIcon/>
                <LogoText/>
              </Logo>
            </Link>
            <div className="mobileButtons">
              <div className="tagLine">{deets.companydescription}</div>
              <a
                className="call"
                href={"tel:" + deets.companyphonenumber}
                title="Call us"
                aria-label="Call us"
              >
                <IconCall width="2.5rem"/>
                {/* <Icon name="call" width="2.5rem" /> */}
              </a>
              <Burger 
                active={menuState.burger}
                onClick ={burgerClick}
              />
            </div>
            <RightSide>
              <div className="tagLine">
                {deets.companydescription}{" "}
                <span>
                  Call{" "}
                  <a
                    className="call"
                    href={"tel:" + deets.companyphonenumber}
                    title="Call us"
                  >
                    {deets.companyphonenumber}
                  </a>
                </span>
              </div>
              <Menu
                menu={topMenu}
                name="topMenu"
              />
            </RightSide>
          </TopMenu>
        </TopMenuContent>
      </TopMenuWrapper>

      <BottomMenuWrapper>
        <BottomMenuContent>
            <Menu
              menu={bottomMenu}
              name="BottomMenu"
              open={menuState.burger || desktop}
              onClose ={subMenuClose}
            >
              { menuState.burger && (
              <MobileExtras>
                <span>
                  <div >
                  <Link style={{display: 'flex', alignItems: 'center'}} to="/log-in" title="Guest Log In" className="login"
                    onClick={e => subMenuClose(true)}
                  >
                    <IconPadlock height = "25px"/>
                    Guest Log In
                  </Link>

                  </div>
                  {wishlistCount(!desktop)}
                </span>
                <span>
                  <div >
                  <Link style={{display: 'flex', alignItems: 'center'}} to="/owners" title="Owner Log In" className="login"
                    onClick={e => subMenuClose(true)}
                  >
                    <IconPadlock height = "25px"/>
                    Owner Log In
                  </Link>

                  </div>
                </span>
                {/* <span>
                  Call&nbsp;
                  <a href={"tel:" + data.wp.companyDetails.companyDetails.companyphonenumber} title="Call us">{data.wp.companyDetails.companyDetails.companyphonenumber}</a>
                  &nbsp;or&nbsp;
                  <Link to="/request-brochure" title="Request a brochure"
                    onClick={e => subMenuClose(true)}
                  >
                    Request a brochure
                  </Link>
                </span> */}

                {showPropertySearch(!desktop)}
          
              </MobileExtras>
              )}
            </Menu>
            {showPropertySearch(desktop)}
            {wishlistCount(desktop)}
        </BottomMenuContent>
      </BottomMenuWrapper>
    </>
  )
}
const Burger = ({active,onClick}) => {

  const updateBurger = (e) => {
    if(onClick){
      onClick();
    }
    document.body.classList.toggle("noScroll")
  }
  return (
    <>
      <MenuButton
        onClick={(e) => updateBurger(e)}
      >
        <IconMenu width="2.5rem" />
      </MenuButton>
    </>
  )
}

export default Header
