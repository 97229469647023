import React, { useState } from "react"
import styled from "styled-components"
import ListMenu from "./listMenu"
import SocialAccounts from "../socailAccounts/socialAccounts"

import { useStaticQuery, graphql } from "gatsby"
// Vars


const FooterWrapper = styled.div`
  ${props => props.theme.wrappersContainers.primaryWrapper};
  color: white;
  ${props => props.theme.fonts.smallerFontSize};

    @media ${props => props.theme.mq.sm} {
      font-size:inherit;
    }
  

  .titleWrapper{
    pointer-events: auto;
    position: relative;
    margin-top:1rem;
    border-top: 1px solid white;
    padding-top:1rem;
    display:flex;
    align-items:center;

      @media ${props => props.theme.mq.md} {
        pointer-events: none;
        padding:0;
        margin:0;
        border:0;
      }

      &.accordian{

        &:after{
          position: absolute;
          /* top:1rem; */
          right:0;
          content: '';
          width: 1.5rem;
          height: 1.5rem;
          background-image: url(/svgs/icons/plus-circle.svg);
          background-size: 1.5rem;
          background-repeat: no-repeat;
          background-position: center;
          @media ${props => props.theme.mq.md} {
            background-image: none;
          }
        }

        &.active:after{
          content: '';
          background-image: url(/svgs/icons/minus-circle.svg);
          @media ${props => props.theme.mq.md} {
            background-image: none;
          }
        }

      }

      h3 {
        ${props => props.theme.fonts.primaryFont};
        font-size: 1rem;
        font-weight: 700;
        line-height: 1.5rem;
        color: white;
        margin: 0;
        max-width: 70%;

        @media ${props => props.theme.mq.md} {
          font-size: 1.125rem;
          margin: 0 0 2rem 0;
        }
      }
  }

`
const FooterTemplate = styled.div.attrs({
  className: "footerTemplate",
})`
  color: white;
  margin: 0 auto;
  margin: 0;
  display: grid;
  grid-template-areas: 
  "menuOne"
  "menuTwo"
  "menuThree"
  "siteCredits";
  overflow: hidden;

  @media ${props => props.theme.mq.md} {
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: auto;
    grid-template-areas: 
    "menuOne menuTwo"
    "menuThree siteCredits";
  }

  @media ${props => props.theme.mq.lg} {
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: auto;
    grid-template-areas: 
    "menuOne menuTwo menuThree siteCredits"
    "menuOne menuTwo menuThree siteCredits";
  }

    a{
      color:white;
    }

    .menu-0{
      grid-area: menuOne;
    }

    .menu-1{
      grid-area: menuTwo;
    }

    .menu-2{
      grid-area: menuThree;
    }

    .siteCredit {
      grid-area: siteCredits;

      @media ${props => props.theme.mq.sm} {
        padding:0;
        margin:0;
        border:0;
      }     

      a{
        text-decoration:none;
        font-weight:bold;
      } 

      .socialAccountsWrapper{ 
        border-bottom:1px solid white;
        padding-bottom:1rem;
        margin:2rem 0 1rem 0;

        @media ${props => props.theme.mq.sm} {
          border:0;
          padding:0;
        }  

        .socialIcons {
          a {
              svg {
                  fill: white;
              }
              &:hover {
                  svg {
                      fill: ${props => props.theme.colors.teriary};
                  }
              }
          }

        }

      }


    }


`

const FooterMenu = props => {

  //State
  const [count, setCount] = useState({
    activeIndex: -1
  })


  function handleClick(clickedIndex) {

    const activeIndex = count.activeIndex

    const newIndex = activeIndex === clickedIndex ? -1 : clickedIndex

    setCount(setCount => ({
      ...setCount,
      activeIndex: newIndex
    }))
  }

  const renderMenuTitle = (menuItem, i) => {

    return menuItem.label &&
      menuItem.menu_icon.menuIcon.showMenuText === true ? (
        <div
          className={count.activeIndex === i ? 'titleWrapper accordian active' : 'titleWrapper accordian'}
          role="button"
          tabIndex="0"
          index={i}
          onClick={() => handleClick(i)}
          onKeyDown={() => handleClick(i)}
        >
          <h3>{menuItem.label}</h3></div >
      ) : (
        ""
      )
  }

  const renderFooterMenu = props.footerMenu.map((menuItem, i) => {
    return (
      <div
        key={"footerMenu-" + i}
        className={
          "menuBlock menu-" + i + " " + menuItem.label.replace(/\s+/g, "-").toLowerCase()
        }
      >
        {renderMenuTitle(menuItem, i)}
        <ListMenu
          class={count.activeIndex === i ? 'active' : ''}
          menuItem={menuItem.subMenu}
          templateName={menuItem.label.replace(/\s+/g, "-").toLowerCase()}
        ></ListMenu>
      </div>
    )
  })

  const data = useStaticQuery(graphql`
  query FootQuery {
    wp {
      companyDetails {
        companyDetails {
          companyname
          companyemail
          companyphonenumber
        }
      }
    }
  }
`)

  const deets = data.wp.companyDetails.companyDetails

  return (
    <FooterWrapper>
      <FooterTemplate>

        {renderFooterMenu}

        <div className="siteCredit">

          <div className="titleWrapper">
            <h3>Get in Touch</h3>
          </div>


          Call: <a href={"tel:" + deets.companyphonenumber} title="Call us">{deets.companyphonenumber}</a>

          <p>Email: <a href={"mailto:" + deets.companyemail} title="Email us">{deets.companyemail}</a></p>

          <SocialAccounts />

        </div>

      </FooterTemplate>
    </FooterWrapper>
  )
}

export default FooterMenu
