import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.span`
    color: ${props => props.titleColor};
`

const Heading = ({ children, as = 'span', type, color }) => {
    return (
        <Wrapper className={type} as={as} titleColor={color}>{children}</Wrapper>
    );
};

export default Heading;