import { css } from "styled-components"

const select = css`
  padding: 1rem;
  border: 1px solid ${(props) => props.theme.colors.mediumGrey};
  border-radius: 4px;
  color: ${(props) => props.theme.colors.primaryFontColor};
  background-color: white;
  width: 100%;
  font-size: inherit;
  line-height: inherit;
  min-width: 140px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
`

const input = css`
  padding: 1rem;
  border: 1px solid ${(props) => props.theme.colors.mediumGrey};
  border-radius: 4px;
  color: ${(props) => props.theme.colors.primaryFontColor};
  background-color: white;
  width: 100%;
  font-size: inherit;
  line-height: inherit;
  min-width: 140px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
`

const checkbox = css`
  display: inline-block;
  width: 22px;
  height: 22px;
  top: 5px;
  background: transparent;
  border: 1px solid ${(props) => props.theme.colors.mediumGrey};
  border-radius: 4px;
  transition: all 150ms;
  padding: 0 2px;
  cursor: pointer;
  position: relative;
  .svgWrapper {
    pointer-events: none;
    position: absolute;
    top: 0;
    right: 0.1rem;
    fill: ${(props) => props.theme.colors.primary};
  }
`

const formElements = {
  select,
  input,
  checkbox,
}

export default formElements
