import React from "react"
import { ThemeProvider } from "styled-components"
import mq from "./mediaQueries"
import fonts from "./fonts"
import colors from "./colors"
import wrappersContainers from "./wrappersContainers"
import buttonStyles from "./components/button/buttonStyle"
import buttons from "./buttons"
import menu from "./components/menu/menuStyles"
import formElements from "./formElements"

const theme = {

  mq: mq,

  colors: colors,

  fonts: fonts,

  wrappersContainers: wrappersContainers,

  button: buttonStyles,

  buttons: buttons,

  menu: menu,

  formElements: formElements,

}

const Theme = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
)

export default Theme
