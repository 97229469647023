import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"

import MailChimpForm from "./mailChimpForm"

const NewsletterWrapper = styled(BackgroundImage)`
  padding-top: 3rem;
  padding-bottom: 3rem;
  color: white;
  background-color: ${(props) => props.theme.colors.secondary};

  .inner {
    ${(props) => props.theme.wrappersContainers.primaryWrapper};

    .newsButton {
      ${(props) => props.theme.buttons.primary};
    }

    .title {
      margin: 0;
    }

    .newsForm {
      display: grid;
      grid-gap: 1rem;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(4, auto);
      grid-template-areas:
        "text"
        "name"
        "email"
        "sub";

      @media ${(props) => props.theme.mq.xs} {
        grid-gap: 1rem 3rem;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: repeat(3, auto);
        grid-template-areas:
          "text text"
          "name email"
          "sub sub";
      }

      @media ${(props) => props.theme.mq.md} {
        ${(props) => props.theme.wrappersContainers.primaryWrapper};
      }

      .text {
        grid-area: text;
      }

      .name {
        grid-area: name;
        input {
          ${(props) => props.theme.formElements.input};
        }
      }

      .email {
        grid-area: email;
        input {
          ${(props) => props.theme.formElements.input};
        }
      }

      .subbers {
        grid-area: sub;
        display: flex;
        align-items: center;
        flex-direction: column;
        @media ${(props) => props.theme.mq.xs} {
          flex-direction: row;
        }

        .vali {
          margin-top: 1rem;
          @media ${(props) => props.theme.mq.xs} {
            margin-top: 0;
            margin-left: 1rem;
          }
        }

        button {
          ${(props) => props.theme.buttons.primary};
          width: 100%;
          @media ${(props) => props.theme.mq.xs} {
            width: fit-content;
          }
        }
      }
    }
  }
`

const Newsletter = () => {
  const data = useStaticQuery(graphql`
    query {
      wp {
        companyDetails {
          companyDetails {
            backgroundImage {
              localFile {
                childImageSharp {
                  fluid(quality: 90, maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const imageData =
    data.wp.companyDetails.companyDetails.backgroundImage.localFile
      .childImageSharp.fluid

  return (
    <NewsletterWrapper fluid={imageData}>
      <div className="inner">
        <MailChimpForm />
      </div>
    </NewsletterWrapper>
  )
}

export default Newsletter
