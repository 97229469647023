import React from "react"
import Alert from '@material-ui/lab/Alert';
import Hidden from '@material-ui/core/Hidden';

const PrivacyMessage = () => {
    const storage = typeof localStorage !== `undefined` ? localStorage : null

    function getSavedState(){
        if(storage) {   
            const show = storage.privacy ? JSON.parse(storage.privacy) : true
            return (show)
        }
        else
            return false
    }

    const [showMessage, setShowMessage] = React.useState(getSavedState());

    function handleClick(e) {
        e.preventDefault();
        setShowMessage(false);
        storage.setItem('privacy', false);
    }

    return (
        <Hidden xsUp =  {!showMessage}>
            <Alert
                onClose={handleClick}
                severity="info"
              >
                We use cookies on our website to make your experience better and to help us monitor and improve our customer service. By continuing we will assume that you are happy to receive all cookies. You can manage the use of cookies through your browser. Read how we use cookies on our <a href="/privacy/">Privacy Policy</a> page.
            </Alert>
        </Hidden>
      );
}

export default PrivacyMessage