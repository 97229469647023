import React from "react"
import SVG from "react-inlinesvg"

const Icon = ({ className="", name, width, height,...props }) => {
  
  
  const iconClass = `svgWrapper ${className}`;

  const src = "/svgs/icons/" + name + ".svg"

  return (
    <SVG className={ iconClass } src={src} width={width} height={height}/>
  )
}

export default Icon