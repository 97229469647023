import colors from "../../colors"

const buttonStyles = {
  type: {
    primary: {
      border: colors.secondary + " 2px solid",
      color: colors.secondary,
      backgroundColor: "transparent",
      padding: ["0.5rem", "1rem"].join(" "),
      fontStyle: "italic",
      hover: {
        backgroundColor: colors.secondary,
        color: "white",
      },
    },
    secondary: {
      border: colors.secondary + " 2px solid",
      color: "white",
      backgroundColor: colors.secondary,
      padding: ["0.5rem", "1rem"].join(" "),
      fontStyle: "italic",
      hover: {
        backgroundColor: "transparent",
        color: colors.secondary,
      },
    },
    tertiary: {
      border: colors.secondary + " 2px solid",
      color: "white",
      backgroundColor: colors.secondary,
      padding: ["0.5rem", "1rem"].join(" "),
      fontStyle: "italic",
      hover: {
        border: "#ab4619 2px solid",
        backgroundColor: "#ab4619",
      },
    },
  },
}



export default buttonStyles
