const colors = {

  primary: "#7fa820",
  primaryRGB: "127, 168, 32",

  secondary: "#5c7faa",
  secondaryRGB: "92, 127, 170",

  tertiary: "#d4ebf3",
  tertiaryRGB: "212, 235, 243",

  lightestGrey: "#F7F7F7",
  lightGrey: "#eaeaea",
  mediumGrey: "#c4c4c4",
  darkGrey: "#4a4a44",

  primaryFontColor: "#4a4a44",

  blue: "#5c7faa",
  lightBlue: "#d4ebf3",
  error: "#BE4E1C",
}

export default colors
