import React from "react"
import PropTypes from "prop-types"
import { createGlobalStyle } from "styled-components"
import Header from "../components/header/header"
import Footer from "../components/footer/footer"

const Layout = ({ children }) => {
  return (
    <>
      <GlobalStyles />
        <Header />
      {/* <PropertyContextProvider> */}
      {children}
      {/* </PropertyContextProvider> */}
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

const GlobalStyles = createGlobalStyle`

  * {
    overflow-anchor: none;
  }

  html {
      height: 100%;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  }

  body {
    ${props => props.theme.fonts.primaryFont};
    ${props => props.theme.fonts.standardFontSize};
    color: ${props => props.theme.colors.primaryFontColor};
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizelegibility;
    height: 100%;   
    background-color: white;
    margin:0;
    padding:0;

    &.noScroll{
      overflow: hidden;
    }
  }

  *,
  *:before,
  *:after {
      -webkit-box-sizing: inherit;
      -moz-box-sizing: inherit;
      box-sizing: inherit;
  }

  img {
      max-width: 100%;
      height: auto;
  }

  button:focus {
      outline: 0;
  }

  a,
  button {
      cursor: pointer;
      font-size: inherit;
  }

  *{
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important; 
    -webkit-focus-ring-color: rgba(255, 255, 255, 0) !important; 
    outline: none !important;
  }

  a{
    color: ${props => props.theme.colors.primary};
    transition: all 0.3s ease-in-out;
    word-wrap: break-word;
  }

  ::placeholder {
    color: ${props => props.theme.colors.primaryFontColor};
      opacity: 1;
  }

  :-ms-input-placeholder {
    color: ${props => props.theme.colors.primaryFontColor};
  }

  ::-ms-input-placeholder {
    color: ${props => props.theme.colors.primaryFontColor};
  }

  select::-ms-expand {
      display: none;
  }

  .gatsby-image-wrapper{
      height:100%;
  }

  @media print {
    .dontPrint{
      display:none;
    }
  }

  /* --- Fonts --- */

  h1 {
    ${props => props.theme.fonts.hTags};
    ${props => props.theme.fonts.h1};
  }

  h2 {
    ${props => props.theme.fonts.hTags};
    ${props => props.theme.fonts.h2};
  }

  h3 {
    ${props => props.theme.fonts.hTags};
    ${props => props.theme.fonts.h3};
  } 

  h4 {
    ${props => props.theme.fonts.hTags};
    ${props => props.theme.fonts.h4};
  }

  h5 {
    ${props => props.theme.fonts.hTags};
    ${props => props.theme.fonts.h5};
  }

  h6 {
    ${props => props.theme.fonts.hTags};
    ${props => props.theme.fonts.h6};
  }

  p {
    margin-top: 0;
    margin-bottom: 1rem;
  }


`

export default Layout