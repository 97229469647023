import styled from "styled-components"

const Button = styled.button`
  appearance: none;
  padding: 0;
  margin: 0;
  border: 0;
  background-color: transparent;
  color: inherit;
 // width: 100%;
 // text-align: left;
  cursor: pointer;
  font-family: inherit;
  `

export default Button
